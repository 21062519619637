/* eslint-disable @typescript-eslint/no-unused-expressions */
import network from '../../utils/network';

export interface createTicketInterface {
  name?: string;
  ticketProviderId: number;
  uuid?: string;
  imageUrl?: string | null;
  newUserName?: string;
  newUserEmail?: string;
  newUserPhoneNumber?: string;
  type?: string;
  dateStart?: any;
  dateEnd?: any;
  user?: any;
  ticketTypeId?: string;
  eventId?: number;
}

interface getTicketParams {
  limit?: number;
  afterCursor?: string;
  beforeCursor?: string;
  searchText?: string;
  ticketProviderId?: string;
  location?: any;
}

interface updateTicketInterface {
  id: any;
  ticketType?: any;
}

export interface retryMintingTicketInterface {
  userId: number;
  ticketId: number;
  ticketProviderId: number;
}

export const getTickets = async ({
  limit,
  afterCursor,
  beforeCursor,
  searchText,
  ticketProviderId,
  location,
}: getTicketParams) => {
  let params: { [key: string]: any } = {};

  params.limit = limit;

  afterCursor ? (params.afterCursor = afterCursor) : '';
  beforeCursor ? (params.beforeCursor = beforeCursor) : '';
  searchText ? (params.searchText = searchText) : '';
  ticketProviderId && parseInt(ticketProviderId) !== 0 ? (params.ticketProviderId = ticketProviderId) : '';

  const response = await network.get({
    path: `/tickets`,
    options: {
      params,
    },
  });
  return response?.data;
};

export const createTicketService = async (data: createTicketInterface) => {
  let user: { [key: string | number]: any } = {};
  if (data.uuid) {
    user['uuid'] = data.uuid;
  } else {
    user.name = data.newUserName;
    user.email = data.newUserEmail;
    user.phoneNumber = data.newUserPhoneNumber;
  }

  const ticket = {
    eventId: data.eventId,
    ticketTypeUuid: data.ticketTypeId,
    ticketProviderId: data.ticketProviderId,
    user: {
      ...data.user,
    },
    imageUrl: data.imageUrl ? data.imageUrl : '',
  };

  const response = await network.post(`/tickets`, ticket);
  return response?.data;
};

export const deleteTicket = async (id: string) => {
  const response = await network.delete(`/tickets/${id}`);
  return response?.data;
};

export const updateTicketService = async (data: updateTicketInterface) => {
  const response = await network.patch(`/tickets/$s{data.id}`, data);
  return response;
};

export const retryTicketMinting = async (obj: retryMintingTicketInterface) => {
  const response = await network.post(`/tickets/retry-minting`, obj);
  return response?.data;
};
