/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState } from 'react';
import { Autocomplete, Box, Button, Grid, Modal, TextField, Switch, FormControlLabel, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const ModalTitle = styled('h2')(({ theme }) => ({
  overflow: 'hidden',
  textAlign: 'center',
  marginTop: '0px',
}));

const ModalSubTitle = styled('h3')(({ theme }) => ({
  overflow: 'hidden',
  textAlign: 'center',
  marginTop: '2rem',
}));

const ButtonDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '38rem',
  bgcolor: 'background.paper',
  p: 4,
  pt: 3,
  pb: 1,
  borderRadius: 2,
};

const SwitchesDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end'
}))

interface optionType {
  id: number;
  name: string;
  uuid: string;
}

interface CreateTicketModalProps {
  title: string;
  openModal: boolean;
  closeModal: () => any;
  submitForm: () => any;
  inputValueHandler: (field: string, value: string | number) => any;
  newUserHandler: (value: boolean) => any;
  ticketProviders: any;
  users: any;
  newUser: any;
  newUserChangeHandler: (field: string, value: string | number) => any;
  saleEnableChangeHandler: (field: string, value: string | number) => any;
  saleEnabled: any;
  saleEnabledHandler: (value: boolean) => any;
  createButtonClicked: boolean
  events?: any;
  ticketTypes?: any;
  ticketValues?: any
}

const CreateTicketModal: React.FC<CreateTicketModalProps> = ({
  title,
  openModal,
  closeModal,
  submitForm,
  inputValueHandler,
  ticketProviders,
  users,
  newUserHandler,
  newUser,
  newUserChangeHandler,
  events,
  ticketTypes,
  ticketValues,
  createButtonClicked
}) => {

  const [ eventValue, setEventValue ] = useState<any>(null);
  const [ userValue, setUserValue ] = useState<any>(null);
  const [ ticketTypeValue, setTicketTypeValue ] = useState<any>(null);

  const clearStates = () => {
    setEventValue(null)
    setUserValue(null)
    setTicketTypeValue(null)
  }

  return (
    <>
      <Modal 
        open={openModal}
        onClose={() => {
          clearStates()
          closeModal()
        }} 
        aria-labelledby="modal-title"
      >
        <Box sx={style}>
          <ModalTitle id="modal-title">{title}</ModalTitle>
          <SwitchesDiv>
            <FormControlLabel
              value="start"
              control={<Switch color="primary" onChange={(e) => {
                e.target.checked && setUserValue(null)
                newUserHandler(e.target.checked)
              }} />}
              label={<strong>New User</strong>}
              labelPlacement="start"
              style={{ display: 'flex' }}
            />
          </SwitchesDiv>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={ticketProviders.filter((provider: any) => provider.id)}
                getOptionLabel={(option: optionType) => option.name}
                autoComplete
                includeInputInList
                onChange={(e: any, newValue: optionType | null) => {
                  if(!newValue || newValue?.id !== ticketValues?.ticketProviderId){
                    setEventValue(null)
                    setUserValue(null)
                    setTicketTypeValue(null)
                  }
                  inputValueHandler('ticketProviderId', newValue ? newValue?.id : 0);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={createButtonClicked && ticketValues.ticketProviderId === 0 ? true : false}
                    helperText={createButtonClicked && ticketValues.ticketProviderId === 0 ? 'Ticket Provider is required' : ''}
                    label="Ticket Provider *"
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={events.filter((event: any) => event.id)}
                getOptionLabel={(option: optionType) => option.name}
                value={ eventValue || null }
                includeInputInList
                onChange={(e: any, newValue: optionType | null) => {
                  if(!newValue){
                    setTicketTypeValue(null)
                    setEventValue(null)
                  }else {
                    newValue ? setEventValue(newValue) : null;
                  }
                  inputValueHandler('eventId', newValue ? newValue?.id : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={createButtonClicked && ticketValues.eventId === 0 ? true : false}
                    helperText={createButtonClicked && ticketValues.eventId === 0 ? 'Event is required' : ''}
                    label="Event *" 
                    fullWidth
                    variant="standard"

                  />
                )}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={newUser?.newUserExists}
                options={users}
                getOptionLabel={(option: any) => `${option.name} (${option.phoneNumber})` }
                autoComplete
                value={userValue || null}
                includeInputInList
                onChange={(e: any, newValue: optionType | null) => {
                  newValue ? setUserValue(newValue) : null;
                  inputValueHandler('uuid', newValue ? newValue?.uuid : '');
                }}
                renderInput={(params) => <TextField {...params} error={createButtonClicked && ticketValues.uuid === '' && !newUser?.newUserExists ? true : false}
                helperText={createButtonClicked && ticketValues.uuid === '' && !newUser?.newUserExists ? 'User is required' : ''}  label="User *" fullWidth variant="standard" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={ticketTypes}
                getOptionLabel={(option: optionType) => option.name}
                autoComplete
                value={ticketTypeValue || null}
                includeInputInList
                onChange={(e: any, newValue: optionType | null) => {
                  newValue ? setTicketTypeValue(newValue) : null;
                  inputValueHandler('ticketTypeId', newValue ? newValue?.uuid : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params} 
                    error={createButtonClicked && ticketValues.ticketTypeId === '' ? true : false}
                    helperText={createButtonClicked && ticketValues.ticketTypeId === '' ? 'Ticket type is required' : ''}
                    label="Ticket Type *" 
                    fullWidth 
                    variant="standard" 
                  />
                )}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Image URL"
                fullWidth
                value={ticketValues?.imageUrl}
                autoComplete="given-image-url"
                variant="standard"
                onChange={(e) => inputValueHandler('imageUrl', e.target.value)}
              />
            </Grid>
          </Grid>

          <div style={{ display: newUser.newUserExists ? 'block' : 'none' }}>
            <Divider />

            <ModalSubTitle>New User</ModalSubTitle>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  error={createButtonClicked && newUser?.newUserExists && newUser?.userFieldsValues?.name   === '' ? true : false}
                  helperText={createButtonClicked && newUser?.newUserExists && newUser?.userFieldsValues?.name === '' ? 'User name is required' : ''}
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  defaultValue={newUser?.userFieldsValues?.name || ''}
                  onChange={(e) => newUserChangeHandler('name', e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="email"
                  error={createButtonClicked && newUser?.newUserExists && newUser?.userFieldsValues?.email === '' ? true : false}
                  helperText={createButtonClicked && newUser?.newUserExists && newUser?.userFieldsValues?.email === '' ? 'User Email is required' : ''}
                  name="email"
                  label="Email"
                  fullWidth
                  autoComplete="given-email"
                  variant="standard"
                  defaultValue={newUser?.userFieldsValues?.email || ''}
                  onChange={(e) => newUserChangeHandler('email', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  error={createButtonClicked && newUser?.newUserExists && newUser?.userFieldsValues?.phoneNumber === '' ? true : false}
                  helperText={createButtonClicked && newUser?.newUserExists && newUser?.userFieldsValues?.phoneNumber === '' ? 'User Phone number is required' : ''}
                  label="Contact Number"
                  fullWidth
                  autoComplete="given-name"
                  variant="standard"
                  defaultValue={newUser?.userFieldsValues?.phoneNumber || ''}
                  onChange={(e) => newUserChangeHandler('phoneNumber', e.target.value)}
                />
              </Grid>
            </Grid>
          </div>

          <ButtonDiv>
            <Button variant="contained" onClick={() => {
              clearStates()
              closeModal()
            }} sx={{ mt: 3, ml: 1 }} color="inherit">
              Close
            </Button>
            <Button variant="contained" onClick={() => {
              clearStates()
              submitForm()
            }} sx={{ mt: 3, ml: 1 }} color="primary">
              Create
            </Button>
          </ButtonDiv>
        </Box>
      </Modal>
    </>
  );
};

export default CreateTicketModal;
