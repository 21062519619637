/* eslint-disable @typescript-eslint/no-unused-expressions */
import { getFingerprint } from '../../utils/auth';
import network from '../../utils/network';

export interface createTicketProviderInterface {
  name: string;
}

interface getTicketProviderParams {
  limit?: number;
  afterCursor?: string;
  beforeCursor?: string;
  searchText?: string;
  location?: any;
  ticketProviderStatus?: string;
}

export const getTicketProviders = async ({
  limit,
  afterCursor,
  beforeCursor,
  searchText,
  location,
  ticketProviderStatus,
}: getTicketProviderParams) => {
  let params: { [key: string]: any } = {};

  params.limit = limit;

  afterCursor ? (params.afterCursor = afterCursor) : '';
  beforeCursor ? (params.beforeCursor = beforeCursor) : '';
  searchText ? (params.searchText = searchText) : '';
  ticketProviderStatus ? (params.verificationStatus = ticketProviderStatus) : '';

  const response = await network.get({
    path: `/ticket-providers`,
    options: {
      params,
    },
  });
  return response?.data;
};

export const createTicketProviderService = async (data: createTicketProviderInterface, location: any) => {
  const response = await network.post(`/ticket-providers`, data);
  return response?.data;
};

export const deleteTicketProvider = async (id: string, location: any) => {
  const response = await network.delete(`/ticket-providers/${id}`);
  return response?.data;
};

export const approveTicketProviderService = async (ticketProviderId: number) => {
  const response = await network.post(`/ticket-providers/${ticketProviderId}/accept`);
  return response?.data;
};

export const rejectTicketProviderService = async (ticketProviderId: number) => {
  const response = await network.post(`/ticket-providers/${ticketProviderId}/reject`);
  return response?.data;
};

export const accountTakeoverService = async (ticketProviderId: number) => {
  const fingerprint = getFingerprint();

  const response = await network.post(`/ticket-providers/hand-off-token`, { ticketProviderId, fingerprint });
  return response?.data;
};
